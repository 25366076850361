


























































import { BCard, BCol, BOverlay, BRow } from "bootstrap-vue";
import { addMonths, parse, format, parseISO } from "date-fns";
import { Component, Vue, Watch } from "vue-property-decorator";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import "flatpickr/dist/flatpickr.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import { $themeColors } from "../../../themeConfig";
import userModel from "@/api/user.model";
import MetricsCard from "./MetricsCard.vue";
import { AlertKind, showAlert } from "@/helpers";

@Component({
	components: { flatPickr, BCard, BRow, BCol, BOverlay, vSelect, MetricsCard },
})
export default class Dashboard extends Vue {
	isLoading = false;

	roleOptions = [
		{ label: "Administrador", code: "ADMINISTRATOR" },
		{ label: "Assessor", code: "ASSESSOR" },
		{ label: "Avaliador", code: "EVALUATOR" },
		{ label: "Promotor", code: "PROSECUTOR" },
		{ label: "Proponente", code: "PROPONENT" },
	];

	groupByOptions = [
		{ label: "Dia", code: "DAY" },
		{ label: "Semana", code: "WEEK" },
		{ label: "Mês", code: "MONTH" },
	];

	metricsOptions = {
		groupBy: "DAY",
		countType: "CUMULATIVE",
		startDate: addMonths(new Date(), -1),
		endDate: new Date(),
		filter: { role: "PROPONENT" },
	};

	flatPickrConfig = {
		locale: Portuguese,
		enableTime: false,
		dateFormat: "Z",
		altInput: true,
		altFormat: "d/m/Y",
	};

	charts: any[] = [{ model: userModel, definition: { options: {} } }];

	async mounted() {
		await this.changedMetricsOptions(this.metricsOptions);
	}

	@Watch("metricsOptions", { deep: true })
	async changedMetricsOptions(options: any) {
		this.isLoading = true;
		try {
			for (const chart of this.charts) {
				const type = "bar"; // options.countType === "CUMULATIVE" ? "line" : "bar";

				chart.definition = await this.getChart(
					await chart.model.getMetrics({ ...options, countType: undefined }, chart.subEntity),
					type,
					this.roleOptions.find(({ code }) => this.metricsOptions.filter.role === code)?.label ?? "Usuários",
				);
			}
		} catch (error) {
			showAlert(AlertKind.ERROR, "Não foi possível carregar. Tente novamente.");
			console.error(error);
		}
		this.isLoading = false;
	}

	getChart(metrics: any, type: string, title: string) {
		return {
			title,
			type,
			series: [
				{
					name: title,
					data: metrics.map((dataPoint: any) =>
						this.metricsOptions.countType === "CUMULATIVE" ? dataPoint.cumulativeCount : dataPoint.count,
					),
				},
			],
			options: {
				theme: {
					monochrome: {
						enabled: true,
						color: ($themeColors as any)?.secondary ?? "#000000",
						shadeTo: "light",
						shadeIntensity: 0.65,
					},
				},
				dataLabels: { enabled: false },
				stroke: { curve: "smooth" },
				xaxis: {
					title: {
						text: this.groupByOptions.find(option => option.code === this.metricsOptions.groupBy)?.label,
						style: { fontSize: "12px", fontWeight: "bold", color: "#555" },
					},
					categories: metrics.map((dataPoint: any) => this.formatPeriod(dataPoint.period)),
				},
			},
			firstValue: metrics[0]?.cumulativeCount,
			lastValue: metrics[metrics.length - 1]?.cumulativeCount,
		};
	}

	formatPeriod(period: string) {
		try {
			switch (this.metricsOptions.groupBy) {
				case "DAY":
					return format(parseISO(period), "dd/MM/yyyy");
				case "WEEK":
					return format(parse(period, "RRRR:II", new Date()), "dd/MM/yyyy");
				case "MONTH":
					return format(parse(period, "yyyy-MM", new Date()), "MMM yyyy");
			}
		} catch (error) {
			console.error(error);
			return "";
		}
	}
}
